import { Link } from 'react-router-dom';
import styles from "@css/navbar.module.css";
import logo from "@resources/global/logo.png";
import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';

function Navbar({ toggleTheme }) {
    const [imgToggle, setImgToggle] = useState(false);
    const [isToggleDisabled, setIsToggleDisabled] = useState(false);

    // prevent spamming dark mode button
    const handleToggleClick = () => {
        if (isToggleDisabled) return; 

        setImgToggle(true);
        toggleTheme();
        setIsToggleDisabled(true);

        setTimeout(() => {
            setIsToggleDisabled(false);
        }, 800);
    };

    return (
        <nav id={styles.navbar}>
            <Link to="/"><img src={logo} id={styles.logo} alt="logo"/></Link>
            <ul id={styles.options}>
                <li className={styles.option}><HashLink smooth to="#events">events</HashLink></li>
                <li className={styles.option}><HashLink smooth to="#donate">donate</HashLink></li>
                <li className={styles.option}><HashLink smooth to="#contact">contact</HashLink></li>
                <li id={styles.toggle_container}>
                    <div id={styles.toggle} className={imgToggle ? styles.animating: styles.reset}
                        onAnimationEnd={() => {setImgToggle(false)}}
                        onClick={handleToggleClick}></div>
                </li>  
            </ul>
        </nav>
    )
}

export default Navbar;