import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from "@css/home.module.css";
import beehive from '@resources/home/beehive.webp';
import nest from '@resources/home/nest.webp';
import grass from '@resources/home/grass.webp';
import pane from '@resources/home/pane.webp';
import { useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, Stage } from '@react-three/drei';
import * as THREE from 'three';

let mouseX = 0;
let mouseY = 0;

function Bee(props) {
    const { scene, animations } = useGLTF(`${process.env.PUBLIC_URL}/bee_minecraft.glb`);

    let mixer;
    // bee default flapping animation
    if (animations.length) {
        mixer = new THREE.AnimationMixer(scene);
        animations.forEach(clip => {
            const action = mixer.clipAction(clip)
            action.play();
        });
    }

    useFrame((state, delta) => {
        mixer?.update(delta);
        // bee smoothly rotates towards cursor position
        if (scene) {
            scene.rotation.y += (mouseX / window.innerWidth * 1.5 / Math.PI + 2.5 - scene.rotation.y) * 0.02;
            scene.rotation.x += (mouseY / window.innerHeight * 1.5 / Math.PI + 0.1 - scene.rotation.x) * 0.02;
        }
    });

    return <primitive object={scene} scale={0.006} {...props} />;
}

function BenchCatFish(props) {
    const { scene } = useGLTF(`${process.env.PUBLIC_URL}/bench_cat_fish_sign.glb`);
    
    scene.rotation.x = -1;
    scene.rotation.z = 0.3;
    
    
    useFrame((state, delta) => {
        if (scene) {
            scene.rotation.x += (mouseY / window.innerHeight * 1 / Math.PI - 1.2 - scene.rotation.x) * 0.01;
        }
    });

    return <primitive object={scene}  {...props} />;
}

function Axolotl(props) {
    const { scene, animations } = useGLTF(`${process.env.PUBLIC_URL}/minecraft_axolotl.glb`);  
 
    scene.rotation.y = 1.6;
    scene.rotation.x = 0.1;

    let mixer;
    if (animations.length) {
        mixer = new THREE.AnimationMixer(scene);
        animations.forEach(clip => {
            const action = mixer.clipAction(clip)
            action.play();
        });
    }

    const [startAnimations, setStartAnimations] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setStartAnimations(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useFrame((state, delta) => {
        if (startAnimations) {
            mixer?.update(delta);
            if (scene) {
                scene.rotation.y += (mouseX / window.innerWidth * 1.5 / Math.PI + 1.6 - scene.rotation.y) * 0.05;
                scene.rotation.x += (mouseY / window.innerHeight * 1.5 / Math.PI + 0.1 - scene.rotation.x) * 0.05;
            }
        }
    });

    return <primitive object={scene} scale={0.013} {...props} />;
}

function DelayedAxolotl() {
    const [showAxolotl, setShowAxolotl] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAxolotl(true);
        }, 1);

        return () => clearTimeout(timer);
    }, []);

    return showAxolotl ? <Axolotl /> : null;
}

function Home() {
    // for 3d model animations based on cursor position
    const handleMouseMove = (event) => {
        mouseX = event.clientX - window.innerWidth / 2;
        mouseY = event.clientY - window.innerHeight / 2;
    };
    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <section id={styles.container} className={styles.home}>
            {/* Welcome | Card 1 */}
            <div id="welcome" style={{marginBottom: '-2rem'}}></div>
            <motion.div
            initial={{ opacity: 0, transform: "translateY(40px)" }}
            whileInView={{ opacity: 1, transform: "translateY(0)" }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            viewport={{ once: true }}
            id={styles.welcome} className={styles.big_card}>
                <div className={styles.big_card_content}>
                    <h2>Buzz off to the official MC server of</h2>
                    <h1>University of Toronto</h1>
                    <p>Play alongside friends, in a server with a <span className={styles.bold_italic}>resource</span> world for gathering resources
                        in vanilla survival—resetting every big update, a <span className={styles.bold_italic}>build</span> world for building bases
                        in survival with custom biomes, and a <span className={styles.bold_italic}>creative</span> world!
                        <br></br><br></br>The steps to join the Minecraft server are on our {" "}
                        <Link to="https://www.discord.gg/nTTdtkpHxR" target='_blank'>
                            <span className={styles.blue_gradient}>Discord!</span>
                        </Link></p>
                </div>
                <div id={styles.bee_area}>
                    <img id={styles.beehive_front} src={beehive} alt="beehive" />
                    <img id={styles.nest} src={nest} alt="nest" />
                    <img id={styles.grass} src={grass} alt="grass" />
                    <div id={styles.bee_canvas}>
                        <Canvas camera={{ fov: 20 }}>
                            <Stage shadows={null} environment="apartment" intensity={0}>
                                <Bee />
                            </Stage>
                        </Canvas>
                    </div>
                </div>
            </motion.div>
            {/* Cross-Collegiate Events | Card 2 */}
            <div id="events" style={{marginBottom: '-2rem'}}></div>
            <motion.div 
            initial={{ opacity: 0, transform: "translateY(50px)" }}
            whileInView={{ opacity: 1, transform: "translateY(0)" }}
            viewport={{ once: true }}
            transition={{ duration: 0.9, ease: "easeInOut" }}
            className={styles.card_group}>
                <div id={styles.card2_img} className={styles.image_card}></div>
                <div id={styles.card2} className={styles.card}>
                    <div className={styles.text_container}>
                        <h1>Cross-Collegiate Tournaments</h1>
                        <p>
                            If you enjoy the competitive side of Minecraft, then feel free to join UTMC's 
                            teams for competing against other universities and colleges in 
                            tournament events like Hunger Games, Lava Rising and the Spud Showdown.
                            <br></br><br></br>For all events, check the #announcements channel on our Discord for info!
                        </p>
                    </div>
                </div>
            </motion.div>

            {/* Server Events | Card 3 */}
            <motion.div 
            initial={{ opacity: 0, transform: "translateY(50px)" }}
            whileInView={{ opacity: 1, transform: "translateY(0)" }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeInOut" }}
            className={`${styles.card_group} ${styles.wrap_reverse}`}>
                <div id={styles.card3} className={styles.card}>
                    <div className={styles.text_container}>
                        <h1>Server Events</h1>
                        <p>
                            Every couple of weeks, our lively community comes together to take part in some
                            laid-back games hosted on the server.
                            <br></br><br></br>Featuring events like scavenger hunts, mafia and extremely
                            hyper-competitive build battles (pictured)
                        </p>
                    </div>
                </div>
                <div id={styles.card3_img} className={styles.image_card}></div>
            </motion.div>

            {/* In-Person Events | Card 4 */}
            <motion.div 
            initial={{ opacity: 0, transform: "translateY(50px)" }}
            whileInView={{ opacity: 1, transform: "translateY(0)" }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeInOut" }}
             className={styles.card_group}>
                <div id={styles.card4_img} className={styles.image_card}></div>
                <div id={styles.card4} className={styles.card}>
                    <div className={styles.text_container}>
                        <h1>In-Person Events</h1>
                        <h2>We're more than just pixels!</h2>
                        <p>From time to time, us Minecrafters also like to see the light of day!
                            <br></br><br></br>We host parties for Halloween and Christmas, as well as other in-person 
                            events like The Great Block Debate and Minecraft Trivia Night.
                        </p>
                    </div>
                </div>
            </motion.div>

            {/* Donations | Card 5 */}
            <div id="donate" style={{marginBottom: '-2rem'}}></div>
            <motion.div 
            initial={{ opacity: 0, transform: "translateY(50px)" }}
            whileInView={{ opacity: 1, transform: "translateY(0)" }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeInOut" }}
            id={styles.donations_card} className={styles.big_card}>
                <div className={styles.half}>
                    <div id={styles.bench_canvas_container}>
                        <Canvas camera={{ fov: 20 }}>
                            <Stage shadows={null} environment="apartment" intensity={0}>
                                <BenchCatFish/>
                            </Stage>
                        </Canvas>
                    </div>
                </div>
                <div className={styles.half}>
                    <div className={`${styles.donations_text} ${styles.text_container}`}>
                        <h1>Donate!</h1>
                        <h2>Give us your money now</h2>
                        <p>Your {" "}
                            <Link to="https://www.paypal.me/utmc69420" target='_blank'>
                                <span className={styles.blue_gradient}>donations</span>
                            </Link>
                            {" "} go 100% towards funding our servers and events
                            <br></br><br></br>
                            You can even receive a VIP rank! (Starts at $15)
                            <br></br>- Dedicated server slot
                            <br></br>- Custom Discord Role + the VIP Discord Role
                            <br></br>- Custom in-game prefixes and custom chat colours
                            <br></br>- and more
                            <br></br><br></br>
                            Please DM a Discord admin your PayPal name and Minecraft name! {" "}
                        </p>
                    </div>
                </div>
            </motion.div>
            {/* Contact */}
            <div id="contact" style={{ marginBottom: '-2rem' }}></div>
            <motion.div 
            initial={{ opacity: 0, transform: "translateY(50px)" }}
            whileInView={{ opacity: 1, transform: "translateY(0)" }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeInOut" }}
            className={styles.contact}>
                <h1>Contact Us</h1>
                <p>For any questions or concerns, feel free to reach out to us on our {" "}
                    <Link to="https://www.discord.gg/nTTdtkpHxR" target='_blank'>
                        <span className={styles.light_blue_gradient}>Discord</span>
                    </Link>
                    <br></br>or through our email at {" "}
                    <Link to="mailto:uoftminecraft@gmail.com">
                    <span className={styles.light_blue_gradient}>uoftminecraft@gmail.com</span>
                    </Link>
                <br></br></p>
                <div id={styles.axolotl_canvas_container}>
                    <Canvas camera={{ fov: 10 }}>
                        <Stage shadows={null} environment="apartment" intensity={0}>
                            <DelayedAxolotl/>
                        </Stage>
                    </Canvas>
                    <img id={styles.pane} src={pane} alt="pane" />
                </div>
                <p>Hope to see you soon!</p>
            </motion.div>
        </section>
    );
}

export default Home;
