import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import styles from "@css/layout.module.css";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";

import useLocalStorage from "use-local-storage";

function Layout(){
    const [theme, setTheme] = useLocalStorage("theme", "light");
    
    const toggleTheme = () =>{
        setTheme(theme === "dark" ? "light": "dark");
    };

    useEffect(() => {
        document.body.setAttribute("data-theme", theme);
    }, [theme]);

    return(
        <div data-theme={theme}>
            <Navbar toggleTheme={toggleTheme} />
            <div id={styles.scrollable}>
                <Outlet/>
                <Footer/>
            </div>
        </div>
    )
}

export default Layout;