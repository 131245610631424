import { Link } from 'react-router-dom';
import styles from '@css/landing.module.css';
import glass_block from '@resources/landing/glass.webp';
import discord from '@resources/landing/discord.webp';
import eye_of_ender from '@resources/landing/eye_of_ender.webp';
import instagram from '@resources/landing/instagram.webp';

function Landing() {
    return (
        <section id={styles.container}>
            <div id={styles.logo}>
                <img id={styles["glass_block"]} src={glass_block} alt="glass block" />
                <div id={styles.text}>
                    <div id={styles.title}>
                        <p>U</p>
                        <p>T</p>
                        <p>M</p>
                        <p>C</p>
                    </div>
                    <h2 id={styles.subtitle}>university of toronto minecraft</h2>
                </div>
            </div>
            <div id={styles.navbar}>
                <Link to="https://www.discord.gg/nTTdtkpHxR" target='_blank'><img id={styles.discord} src={discord} alt="discord" /></Link>
                <Link to="/home"><img id={styles["eye_of_ender"]} src={eye_of_ender} alt="home page" /></Link>
                <Link to="https://www.instagram.com/uoftminecraft/" target="_blank"><img id={styles.instagram} src={instagram} alt="instagram" /></Link>
            </div>
        </section>
    )
}

export default Landing;