import styles from "@css/footer.module.css";

function Footer() {
    const currentYear = new Date().getFullYear().toString().slice(-2);
    
    return(
        <footer id={styles.footer}>
            <p>Property of UTMC <em>`{currentYear}</em></p>
        </footer>
    )
}

export default Footer;